// Outside cards
.kt-subheader .kt-subheader__main .kt-subheader__title {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    min-height: 40px;
}

.kt-nav .kt-nav__section .kt-nav__section-text {
    color: var(--linkcolor);
}

.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-wrapper .kt-header__topbar-icon {
    background-color: var(--sidebar);
    z-index: 100;
}

.kt-notification .kt-notification__item:hover .kt-notification__item-details .kt-notification__item-title {
    transition: color 0.3s ease;
    color: var(--linkcolor);
}

.btn-outline-brand:hover {
    color: #ffffff;
    background-color: var(--sidebar);
    border-color: var(--sidebar);
}


.kt-sticky-toolbar .kt-sticky-toolbar__item a:hover {
    background-color: var(--linkcolor);
}

.kt-aside,
.kt-aside-menu,
.kt-user-card {
    background-color: var(--sidebar);
}

.kt-aside--on{
    .kt-aside__brand-tools {
        display: none;
    }
}

.dashboard-header {
    background-color: var(--linkcolor);
}


.mat-calendar-body-selected {
    background-color: var(--linkcolor);
}

// Tab Background Color
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: var(--linkcolor);
}

// Checkboxes
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--linkcolor);
}

// Buttons
.btn.btn-label-brand {
    background-color: var(--linkcolorbg);
    color: var(--linkcolor);
}

a.btn.btn-label-brand:focus,
a.btn.btn-label-brand:hover {
    background-color: var(--linkcolor);
}

.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: var(--linkcolor);
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
    color: var(--linkcolor);
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
    color: var(--linkcolor);
}

.mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
    color: var(--linkcolor);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--linkcolorbg);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: var(--linkcolor);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: var(--linkcolor);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--linkcolor);
}


.row .selectors {
    padding-top: 10px;
    margin-bottom: 20px;
    // height: 200px;
    overflow-y: auto;
    align-items: center;
}

// .spinner-container {
//     background: rgba(255, 255, 255, 0.5) !important;
// }


// For numerical-based Angular Material data tables
// Left-aligns the first column, right-aligns the rest (numerical data columns)
.numerical-table {
    display: grid;
    overflow-x: auto;

    .mat-header-cell,
    .mat-cell {
        justify-content: center;
        padding-right: 25px;
        padding-left: 25px;
    }

    .mat-cell,
    .mat-footer-cell {
        font-size: 13px;
    }

    // Alternate row styling
    .mat-row:nth-child(even) {
        background-color: #f9f9f9;
    }

    .mat-row:nth-child(odd) {
        background-color: #ffffff;
    }

    mat-cell:first-of-type,
    mat-footer-cell:first-of-type,
    mat-header-cell:first-of-type {
        padding-right: 12px;
        padding-left: 6px;
        text-align: left;
        justify-content: flex-start;
        flex-grow: 2;
    }

    mat-cell:last-of-type,
    mat-footer-cell:last-of-type,
    mat-header-cell:last-of-type {
        padding-right: 6px;
        padding-left: 12px;
    }

    .mat-table-sticky:first-child {
        border-right: 1px solid #e0e0e0;
    }

    .mat-table-sticky:last-child {
        border-left: 1px solid #e0e0e0;
    }

    button {
        padding: 1px 0;
        text-align: end;
    }

    .negative {
        text-align: end;
        justify-content: flex-end;
        padding-right: 12px;
        padding-left: 12px;
        background-color: rgba(255, 0, 0, .15);
        color: red;
    }

    .neutral {
        text-align: end;
        justify-content: flex-end;
        padding-right: 12px;
        padding-left: 12px;
        background-color: rgba(255, 196, 0, .15);
        color: red;
    }
}


// MGL MAP

mgl-map {
    height: 50vh;
}

.mat-select-value {
    color: #7b7b85;
}


// MESSAGE STATES

.hem-helper-state {
    min-height: 50vh;

    i {
        font-size: 8rem !important;
    }

    img {
        max-width: 160px;
        max-height: 160px;
    }
}

.hem-feature-state {
    height: calc(100% - 60px);

    img {
        max-width: 80px;
        max-height: 80px;
        margin-bottom: 24px;
    }

    color: rgba(0, 0, 0, .75);
}

.hem-empty-state,
.hem-warning-state {
    min-height: 400px;

    i {
        font-size: 6rem !important;
    }
}

.hem-helper-state,
.hem-empty-state,
.hem-warning-state,
.hem-feature-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
        opacity: .5
    }

    i {
        opacity: .2;
    }

    h4 {
        font-size: 1.5em !important;
        opacity: .75;
    }

    p {
        font-size: 1.1em !important;
        margin-top: 6px;
        opacity: .75;
    }
}

.popup tr td {
    margin: 10px;
    padding-left: 10px;
}

//   Report controls
.hem-report-controls,
.hem-boundary-controls,
.hem-date-selector {
    margin-top: 24px;

    .mat-list-item,
    .mat-list-option {
        min-height: 56px;
        height: auto !important;
    }

    .mat-list-base .mat-list-item.mat-list-item-with-avatar,
    .mat-list-base .mat-list-option.mat-list-item-with-avatar {
        height: 900px;
    }

    .mat-list-item-content {
        min-height: 56px;
    }

    .mat-list-base,
    .mat-list-item {
        font-size: 14px !important;
    }
}

.hem-boundary-controls {
    .mat-subheader {
        padding: 16px 16px 0;
    }
}

// Map Legends
.hem-map-legend {
    background-color: #fff;
    border-radius: 3px;
    top: 30px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font: 11px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 10px;
    position: absolute;
    left: 10px;
    z-index: 1;

    h4 {
        margin: 0 0 6px;
        font-size: 11px;
        font-weight: 700;
    }

    div span {
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        margin-right: 5px;
        width: 10px;
        vertical-align: middle;
    }
}

// Map areas selected zoom button
.hem-btn-zoom {
    background-color: var(--linkcolor);
    color: white;
    border-radius: 25px;

    .material-icons {
        color: white;

        padding: 0;
        margin: 0;
        border: 0;
        display: inline;
    }

    mat-icon {
        display: inline-block;
        height: 20px;
        line-height: 0;
        margin: auto;
        margin-right: 8px;
        order: 0;
        position: relative;
        vertical-align: middle;
        white-space: nowrap;
        width: 20px;
        flex-shrink: 0;
    }
}

// Category checkbox list e.g. airbnb, hotel, motel
.hem-category {
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;

    .mat-checkbox {
        margin-right: 16px;
    }

    .mat-icon {
        vertical-align: text-bottom;
        height: 16px;
        width: 14px;
        font-size: 16px !important;
    }
}


.mat-elevation-z4 {
    box-shadow: none;
}

.mat-tab-header {
    padding-right: 31px;
}

.watermark-container {
    position: relative;
}

.watermark {
    position: absolute;
    color: lightgray;
    opacity: 0.5;
    font-size: 5em;
    text-align: center;
    z-index: 5;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.zendesk {
    width: 100%;
    height: 50px;
    align-items: stretch;
    justify-content: center;
    align-content: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
}

.zendesk {

    a:link,
    a:visited {
        background-color: transparent;
        color: #b8bac3;
        text-decoration: none;
        cursor: auto;
    }

    a:active,
    a:hover {
        color: var(--linkcolor);
        transform: scale(1.1);
    }
}

.list-icon {
    height: 24px;
    width: 24px;
}
.kt-login__menu{
    display: flex;
    flex-direction: row;

}

@media screen {
    .printonly {
        display: none;
    }
}

@media print {
    body {
        min-width: 1324px;
        transform: scale(1);
        background: white;
    }

    .row {
        break-inside: avoid-page;
    }

    #print-container {
        margin-top: -40px;
    }

    ul.summary li {
        page-break-before: auto;
        page-break-inside: avoid;
        page-break-after: avoid;

        &:before,
        &:after {
            display: none;
        }
    }

    .cdk-global-scrollblock {
        position: absolute;
        overflow-y: auto;
        height: 4000px;
    }

    #printDialog {
        margin: 10px;
        position: fixed;
        top: 0;
        left: 0;
        min-width: 1324px;
        transform: scale(1);
        background: white;
        height: 4000px;

        .summary-metrics-dialog-header {
            flex-direction: column;

            .analysis {
                display: none;
            }

            .dropdown {
                display: none;
            }
        }

        .summary-metrics-dialog-body {
            flex-direction: column;

            .summary-metrics-dataviz-header {
                display: none;
            }

            .metric-space {
                width: 900px;
            }
        }

        .summary-metrics-dialog-footer {
            overflow: hidden;
            height: auto;

            .dialog-comments {
                .grid2 {
                    grid-template-columns: auto;
                    page-break-before: auto;
                    page-break-inside: avoid;
                    page-break-after: avoid;

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    #survey-dashboard-header {
        display: none;
    }

    #survey-row {
        display: none;
    }

    .kt-sticky-toolbar {
        display: none;
    }

    .kt-aside__brand-logo {
        display: none;
    }

    .kt-header {
        display: none;
    }

    .marketplace-lead {
        display: none;
    }

    #kt_header_mobile {
        display: none;
    }

    mgl-map {
        height: 50vh;
        width: 100%;
    }

    .ta-region-chart {
        height: 370;
    }

    .widget-card {
        height: 370;
        min-height: 370;
    }

    .kt-scrolltop--on .kt-scrolltop {
        display: none;
    }
}

@page {
    size: A4;
    margin: 0;
}

.mat-snack-bar-container {
    margin: unset;
}


.snackbar {


    color: var(--sidebarlinkcolor);
    background-color: var(--sidebar);
    margin-bottom: 60px !important;

}

.snackbar button {
    color: var(--sidebarlinkcolor);

}

.snackbar button span {
    color: white;
}

.link {
    color: var(--linkcolor);
}

.hidefilter a[data-h="4"],
a[data-h="5"],
a[data-h="6"] {
    display: none !important;
}


.hero-selectors {
    flex-wrap: nowrap;

    .mat-chip-list-wrapper {
        margin: 0px;
    }

    .mat-standard-chip {
        border-radius: 1px;
        height: auto;
    }

    mat-chip-list-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap !important;
        align-items: center;
        margin: 10px;
    }

    mat-form-field-label-wrapper {
        top: -25px;
    }

    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        display: none !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
        border-top: 0;
    }

    .mat-form-field-infix {
        position: unset;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-label-wrapper {
        margin: 20px;
    }
}

.map-filter {
    margin-left: 22px;
    margin-right: 22px;
}

.menu-button {
    height: 40px;
}

.right {
    display: flex;
    margin-top: -10px;
    justify-content: flex-end;
    margin-right: 20px;
}

@media (min-width: 1250px) {
    .right {
        margin-top: -40px;
    }
}


.selected-chart {
    color: var(--linkcolor);
}

.unselected-chart {
    color: black;
}

.right mat-icon {
    transform: scale(1.6);
}

.marketplace {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 3px;
    margin-right: 8px;
    background-color: #E3B603;
    color: white;
    border-radius: 3px;
    width: 120px;
    border: none;
}

button.marketplace:hover {
    background-color: #f5c502;
    ;
    color: black
}



.pa-heading-line-right h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 25px;
}


/*add and adjust the lines*/

.pa-heading-line-right h2:after {
    content: '';
    border-top: 5px solid;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
    color: #666666;

}


/*add spacing between heading and line*/

.pa-heading-line-right h2:after {
    margin: 0 0 0 20px;
}


.mat-progress-bar-fill::after {
    background-color: var(--linkcolor);
}

.table-metric {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 65px 65px 65px 65px 10px;
    justify-content: flex-end;
    justify-items: end;
    padding: 1px 2px;
}

mat-form-field.compact {
    .mat-form-field-infix {
        padding-top: 0;
        border-top: none;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-underline {
        bottom: 0;
    }

    .mat-select-arrow-wrapper {
        transform: none;
    }
}

.notif {
    display: flex;
    flex-direction: column;
}

.notification-top-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70px;
    line-height: 70px;
    width: 100%;
    background: var(--linkcolor);
    text-align: center;
    color: #FFFFFF;
    font-family: sans-serif;
    //font-weight: lighter;
    font-size: 18px;
    z-index: 100;
    opacity: 1;
}

.notification-top-bar p {
    padding: 0;
    margin: 0;
}

.notification-top-bar p a {
    padding: 5px 10px;
    border-radius: 3px;
    background: #FFF;
    color: var(--linkcolor);
    font-weight: bold;
    text-decoration: none;
}

.center {
    display: flex;
    justify-content: center;
    font-weight: 400;
}


// For components with expandable dividers like the "In Bound Visits to Region" page
.flex-row {
    display: flex;
    flex-direction: row;
}

.item-50 {
    width: 50%;
    padding: 0px 20px 0px 20px;
}

.item-100 {
    width: 100%;
    padding: 0px 20px 0px 20px;
}

.fullscreen-btn {
    float: right;
    margin: 0px -10px 0px -20px;
}

.details {
    p a
        {
            text-decoration: none;
            img.h32 {
                height: 16px !important;
                margin-left:3px;
                vertical-align:text-bottom;

            }
        }
}

p p a {

        text-decoration: none;
        img.h32 {
            height: 16px !important;
            margin-left:3px;
            vertical-align:text-bottom;

        }
    }

.title-up {
        margin-top: -80px;
        margin-left: 8px;
    }

.selected {
    border: 1px solid var(--linkcolor);
}


#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header {
    display: inline-block !important;
}
