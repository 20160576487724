//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//




// State colors
$kt-state-colors: (
    // Metronic states
    brand: (
        base:  #2b2b2b,
        inverse: #ffffff
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base:  #2b2b2b,
        inverse: #ffffff
    ),
    success: (
        base:  #2b2b2b,
        inverse: #ffffff
    ),
    info: (
        base: #2786fb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff
    )
);

// Brand Color
$kt-brand-inverse-color: #ffffff;
$kt-brand-hover-color: #F5F6FC;

// Secondary Brand Color
$kt-secondary-brand-color: #fbce44;

// Page bg color
$kt-page-bg-color: #f9f9fc;  // page bg color

// Aside bg color
$kt-aside-bg-color: #1B1B2A;

// Layout
$kt-aside-default-width: 285px;
$kt-aside-minimize-width: 100px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (
	desktop: 20px,
	mobile: 15px
);

// Page container
$kt-page-container-width: 1380px; //  fixed page width
