.dashboard-container__wrapper {
    display: flex;
    flex-direction: column;

    .dashboard-header {
        background-color: var(--topheader);
        margin-left: -25px;
        margin-right: -20px;
        margin-top: -26px;
        --text-opacity: 1;
        font-size: 1.5rem;
        height: 110px;

        .dashboard-subheader {
            // To counteract dashboard-header: margin-left/right
            margin-left: 25px;
            margin-right: 25px;
            margin-top:10px;

            .title {
                padding-top: 20px;
                color: rgba(255, 255, 255, var(--text-opacity));
                margin-left: 11px;
            }
        }
    }

    .select-box {
        font-size: 16px;
        font-weight: 600;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: -90px;

        .selectors {
            margin-left: 22px;
            margin-right: 22px;
            //   height: 150px;
            overflow: hidden;

            .rt-button {
                border-radius: 3px;
                height: 40px;
                box-shadow: none;
            }

            .type-ahead-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                border: 2px #d0d3d9 solid;
                border-radius: 3px;
                background: #fff;
                -webkit-transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
                transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
                border-width: 2px;

                height: 100%;
                min-width: 200px;
                margin-bottom: 10px;

                .hero-selectors {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .regions {
                        width: 1000%;
                        margin-right: 10px;
                    }

                    .filters {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-end;

                        .filter-button {
                            display: block;
                            padding: 6px 9px;
                            border-radius: 3px;
                            border: 1px solid #515b6e;
                            font-weight: lighter;
                            white-space: nowrap;
                            cursor: pointer;
                        }

                        .filter-button-icon {
                            stroke-linejoin: round;
                            stroke-linecap: round;
                            fill: none;
                            vertical-align: middle;
                            width: 24px;
                            height: 24px;
                        }

                        .map-button-icon {
                            stroke-linejoin: round;
                            stroke-linecap: round;
                            fill: none;
                            vertical-align: middle;
                            width: 24px;
                            height: 24px;
                        }

                        .datepicker-button {
                            display: flex;
                            flex-direction: column-reverse;
                            padding: 6px 9px 3px 9px;
                            border-radius: 3px;
                            border: 1px solid #515b6e;
                            font-weight: lighter;
                            white-space: nowrap;
                            height: 39px;


                            .mat-icon-button {
                                vertical-align: top;
                            }
                        }
                    }
                }

                .search-button {
                    min-height: 70px;
                    height: 100%;
                    width: 160px;
                    border-radius: 0;
                }
            }

            .type-ahead-box:focus-within {
                border: 2px var(--topheader) solid;
            }


        }

        .column {
            display: flex;
            flex-direction: column;

            .h4 {
                height: 100%;
                display: block;
                margin-top: 10px;
                margin-left: 22px;
                margin-right: 22px;
            }
        }
    }

    .results {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        margin-right: 25px;
    }
}

.row {
    display: flex;
    padding-bottom: 20px;
}

.column {
    display: flex;
    flex-direction: column;
}

.spacer {
    margin-right: 20px;
}

mat-card {
    background-color: #f9f9fc;
    border-radius: 0.475rem;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(216, 220, 230, 0.65);
}

@media (max-width: 1480px) {
    .filters {
        display: none;
        width: 100%;
    }
}
