/* You can add global styles to this file, and also import other style files */
@import '~material-design-icons/iconfont/material-icons.css';
@import 'flexmonster/flexmonster.min.css';

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

// global classes to modify fm-pivot components
.pivot-fields-left {
    #fm-pivot-view {
        .fm-fields-view-wrap {
            left: 0;
            right: auto;

            .fm-btn-open-fields {
                width: 30px;
                height: 30px;
                border-left: none;
                border-right: 1px solid #d5d5d5;

                &:before {
                    transform: rotate(-90deg);
                    font-size: 20px;
                    padding-left: 0;
                    margin-left: -1px;
                    margin-top: -1px;
                }
            }
        }

        .fm-fields-view.fm-instant-view .fm-btn-close-fields:before {
            transform: rotate(90deg);
        }

        .fm-fields-view.fm-instant-view {
            left: 0;
            right: auto;
            border-right: 1px solid #d5d5d5;
            border-left: none;
        }

        .fm-grid-view .fm-grid-layout {
            float: right;
        }

        .fm-charts-view {
            right: 0;

            .fm-header-toolbar.fm-fields-list-padding {
                padding: 20px 20px 20px 40px;
            }
        }
    }
}

.pivot-toolbar-mini {
    #fm-toolbar-wrapper {
        height: 40px;

        #fm-toolbar {
            height: 40px;

            .fm-toolbar-group-left > li,
            .fm-toolbar-group-right > li {
                display: inline-block;
                vertical-align: middle;
                padding: 0;
                position: relative;
                width: auto;
                margin: 0 5px;

                > a {
                    height: 40px;
                    display: flex;
                    align-items: center;

                    > .fm-svg-icon {
                        position: static;
                        text-align: center;
                        width: 30px;
                        flex: 1;

                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    > span {
                        position: static;
                        top: 55px;
                        left: 0;
                        right: 0;
                        flex: 1;
                        width: auto;
                        text-align: left;
                        overflow: visible;
                    }
                }
            }

            li#fm-tab-fullscreen {
                width: auto;
            }

            .fm-divider {
                margin: 0 10px 0 10px;
                height: 20px;
            }

            .fm-dropdown {
                top: 40px;
            }
        }
    }
}

fm-pivot {
    #fm-tab-save {
        display: none !important;
    }
}

#fm-pivot-view .fm-fields-view {
    min-height: 700px !important;
    width: 95% !important;
}

#fm-pivot-view .fm-filter-view .fm-filter-view-content {
    width: 1000px;
    height: 386px;
    min-height: 700px;
}

@media only screen and (max-height: 720px) {
    #fm-pivot-view .fm-fields-view {
        min-height: 500px !important;
        width: 95% !important;
    }

    #fm-pivot-view .fm-filter-view .fm-filter-view-content {
        width: 500px;
        height: 386px;
        min-height: 500px;
    }
}

.mat-tooltip {
    background: lightgrey;
    color: black !important;
}

.hem-purple {
    background-color: #b63c8e !important;
}

.hem-orange {
    background-color: #f46036 !important;
}

.hem-blue {
    background-color: #1ed9cd !important;
}

.hem-yellow {
    background-color: #e3b505 !important;
}
